<template>
	<div>
		<el-container class="full-height">
			<el-header>
				<Header></Header>
			</el-header>
			<el-container>
				<el-aside :class="['aside', asideClass]">
					<Sidebar></Sidebar>
				</el-aside>
				<el-container class="main-container">
					<el-main>
						<Container></Container>
					</el-main>
				</el-container>
			</el-container>
			<el-footer>
				<Footer></Footer>
			</el-footer>
		</el-container>
	</div>
</template>

<script>
import Header from '@/components/layout/Header.vue'
import Sidebar from '@/components/layout/Sidebar.vue'
import Container from '@/components/layout/Container.vue'
import Footer from '@/components/layout/Footer.vue'

export default {
	name: 'Home',
	components: {
		Header,
		Sidebar,
		Container,
		Footer,
	},
	computed: {
		asideClass() {
			if (this.isCollapse) {
				return 'aside'
			} else {
				return 'asideMin'
			}
		},
	},
	data() {
		return {
			menuData: null,
			isCollapse: true,
		}
	},
	mounted() {
		this.init()
	},
	created() {
		this.$eventBus.$on('Home', this.toggleVisibility) // 监听事件
	},
	beforeDestroy() {
		this.$eventBus.$off('Home', this.toggleVisibility) // 清理事件监听
	},
	methods: {
		init() {},
		toggleVisibility() {
			this.isCollapse = !this.isCollapse // 切换显示状态
		},
	},
}
</script>

<style>
html, body {
        height: 100%;
        margin: 0;
    }

    .full-height {
        display: flex;
        flex-direction: column;
        height: 100vh;
    }

    .el-header, .el-footer {
        background-color: #015bb3;
        color: #fff;
        line-height: 60px;
    }

    .el-footer {
        text-align: center;
        flex-shrink: 0;
    }

    .el-aside {
        background-color: #ffffff;
        color: #333;
        /* width: auto !important; */
        border-right: 1px #D3D5DD double;
    }

    .aside{
        width: 0 !important;
    }

    .asideMin{
        width: auto !important;
    }

    .el-container {
        flex: 1;
        display: flex;
        flex-direction: row;
    }

    .main-container {
        flex: 1;
        display: flex;
        flex-direction: column;
    }

    .el-main {
        background-color: #ffffff;
        color: #333;
        padding: 0 !important;
        flex: 1;
    }
</style>
