<template>
	<div>
		<el-row>
			<el-col :span="24">
				<span class="footer">車洗喜股份有限公司 © Copyright 2024 FORULIKE Company. All rights reserved.</span>
			</el-col>
		</el-row>
	</div>
</template>

<script>
export default {
	name: 'Footer',
}
</script>
<style scoped>
.footer {
        font-size: 0.7rem;
    }
</style>
